import HeroNameLink from '../../HeroNameLink/HeroNameLink';
import Lang from '../../../language';

export default function Templ1({
  isUser,
  message,
  msgDamageSize,
  msgWeapon,
  msgAttackZone,
  dmg,
}) {
  const isGenderManA = message.a_her.isGenderMan;
  const isGenderManD = message.d_her.isGenderMan;
  return (
    <div className="toChat">
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_DID</Lang> {isGenderManA ? ' ' : <Lang>CHAT_ER</Lang>}{' '}
      <Lang>CHAT_LUNGE_BUT</Lang>{' '}
      <HeroNameLink userHero={message?.d_her?.hero_id} isUser={!isUser}>
        {message?.d_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_DID</Lang>
      {isGenderManD ? <Lang>CHAT_IS</Lang> : <Lang>CHAT_ER</Lang>}{' '}
      <span className="chat__block1--body--message--item--text--hp--dodge">
        <Lang>DODGE_DID</Lang>
      </span>
      <span>
        <Lang>DODGE</Lang>.
      </span>
    </div>
  );
}
