import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../hooks/useDictionary';
import './styles.scss';
import NpcDialogue from './NpcDialogue';
import Lang from '../language';

function NpcModal({ event, leaveEvent, className }) {
  const [dict, getInfoDictionary] = useDictionary();
  const user = useSelector((state) => state.allUserData.data);
  const dlg = useSelector((state) => state.NpcData.npc_dialogues);
  const [dialog, setDialog] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const modalBaseRef = useRef(null);
  const [npc, setNpc] = useState(null);

  useEffect(() => {
    if (event) {
      setDialog(event);
    } else {
      const buldings = dict.buildings;
      const bld = buldings.filter(
        (el) => el.id === user.h_location.building_id,
      );
      const npc = bld[0]?.npc_id;
      if (dlg && dlg[npc]) {
        setDialog(dlg[npc]);
        setNpc(npc);
      }
    }
  }, [dlg, user.h_location.building_id]);

  useEffect(() => {
    const handleScroll = () => {
      if (modalBaseRef.current) {
        const hasOverflow =
          modalBaseRef.current.scrollHeight > modalBaseRef.current.clientHeight;
        setIsOverflowing(hasOverflow);
      }
    };

    handleScroll();

    const observer = new MutationObserver(handleScroll);
    if (modalBaseRef.current) {
      observer.observe(modalBaseRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
      observer.disconnect();
    };
  }, [modalBaseRef.current]);

  return (
    <div
      className={`npc_modal_base ${isOverflowing ? 'npc_modal_base--texts--extend' : ''}`}
      ref={modalBaseRef}
      style={
        className && { left: '9vw', top: '2vw', width: '30vw', height: '16vw' }
      }
    >
      {dialog ? (
        <NpcDialogue dlg={dialog} leaveEvent={leaveEvent} npcId={npc} />
      ) : (
        <div>
          <span className="npc_modal_base--response">
            <Lang>LOADING</Lang>
          </span>
          <span
            className="npc_modal_base--response"
            onClick={() => leaveEvent()}
          >
            <Lang>EXIT</Lang>
          </span>
        </div>
      )}
    </div>
  );
}

export default NpcModal;
