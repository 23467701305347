import React, { useEffect, useLayoutEffect, useState } from 'react';
import './styles.scss';
import imgLogo from '../../media/img/auth/logo.png';
import UiButtonOne from '../ui/buttons/UiButtonOne';
import { Centrifuge } from 'centrifuge';
import { RequestCommand, Response } from '../../helper/reqRspName';
import { Changer } from '../../manager/managerGameChanger';
import ChatBody from '../chat/ChatBody';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setHeroData } from '../../store/reducer/Slices/SlicesHeroData';
import { setConnection } from '../../store/reducer/Slices';
import Lang from '../language';
import { routConnection } from '../../api/routeConnection';
const connectionUrl = routConnection(process.env.REACT_APP_RUN);

const BattleLog = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const userMain = useSelector((state) => state.allUserData.user_main);
  const [loading, setLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [checkList, setCheckList] = useState(true);
  const [timer, setTimer] = useState(0);
  const [toCent, setToCent] = useState(null);
  const [battleInfo, setBattleInfo] = useState({
    battle: {},
    dest_hero_id: '',
    party1: [],
    party2: [],
  });
  let count = 1;

  let centrifugeInit = new Centrifuge(connectionUrl, {
    data: {
      $type: RequestCommand.RequestGetBattleHistoryLength,
      battle_id: params.id,
    },
  });

  const checkNewData = (resp) => {
    toCent
      ?.rpc('46', {
        $type: RequestCommand.RequestGetBattleHistoryLength,
        battle_id: battleInfo.battle.id,
      })
      .then((resp) => {
        if (
          chatList[chatList.length - 1]?.type !== Response.ResponseBattleEnd
        ) {
          setLoading(true);
          if (resp.data[0].history_count > chatList.length) {
            toCent?.rpc('47', {
              $type: 47,
              battle_id: resp.data[0].battle_id,
              from: chatList.length + 1,
              count: resp.data[0].history_count,
            });
          }
        } else setLoading(false);
      });
    // }
  };

  useEffect(() => {
    checkNewData();
  }, [timer]);

  const getLogs = (xx) => {
    if (count === 1) {
      count = 2;
      centrifugeInit
        .rpc('46', {
          $type: RequestCommand.RequestGetBattleHistoryLength,
          battle_id: xx.battle_id,
        })
        .then((resp) => {
          centrifugeInit
            .rpc('47', {
              $type: 47,
              battle_id: resp.data[0].battle_id,
              from: 1,
              count: resp.data[0].history_count,
            })
            .then(() => {
              setInterval(() => {
                setTimer((prev) => prev + 1);
              }, 4000);
            });
        });
    }
  };

  const processChatData = async (ctx, xx, ss) => {
    for (let i = 0; i < ctx.length; i++) {
      const { toChat } = await processChat(ctx[i], xx);
      ss.push(toChat);
    }
    const finalResult = [];
    for (let i = ss.length - 1; i >= 0; i--) {
      if (i < ss.length - 1 && ss[i + 1]?.sender === 'MOVE') {
        if (
          ss[i].sender !== 'MOVE' ||
          ss[i + 1].data?.created !== ss[i]?.data?.created
        ) {
          finalResult.push({ type: 'Breaker' });
        } else if (
          i > 0 &&
          ss[i + 1].sender === 'MOVE' &&
          ss[i + 1].data?.created !== ss[i]?.data?.created &&
          ss[i + 1].data.a_damag === 0 &&
          ss[i].data.a_damag === 0
        ) {
          ss.push({ type: 'Breaker' });
        }
      }
      finalResult.push(ss[i]);
    }
    return finalResult.reverse();
  };

  const processChat = async (data, xx) => {
    return new Promise((resolve, reject) => {
      try {
        const parsedData = JSON.parse(
          JSON.stringify(Changer.toBattleChat(data, xx)),
        );
        resolve(parsedData);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    let ss = [];
    let xx = [];
    centrifugeInit
      .on('connecting', function (ctxc) {
        centrifugeInit.on('publication', function (ctx) {
          console.log('ctx', ctx.data[0]);
          if (ctx.data[0].logs[0].$type === 22) {
            setBattleInfo(ctx.data[0].logs[0]);
            xx = ctx.data[0].logs[0];
          }
          processChatData(ctx.data[0].logs, xx, ss).then((res) => {
            console.log('res', res);
            setChatList(res);
            setCheckList(false);
          });
        });
      })
      .on('connected', function (ctx) {
        getLogs(ctx.data[0]);
        setToCent(centrifugeInit);
        // dispatch(setConnection(centrifugeInit))
        // getLogs(ctx)
      })
      .on('disconnected', function (ctx) {
        // cookieDel('jwt')
        // redirect('/welcome')
      })
      .connect();
  }, []);

  return (
    <div className="battleLog">
      <div className="battleLog__header">
        <div className="battleLog__header--logo">
          <img src={imgLogo} alt="" />
        </div>
        <div className="battleLog__header--button">
          {/* {userMain.id === '' && (
            <UiButtonOne
              onClick={() => {
                window.location.replace('/welcome');
              }}
            >
              <Lang>CHAT_TO_BATTLE</Lang>!
            </UiButtonOne>
          )} */}
        </div>
      </div>
      <div className="battleLog__body">
        {checkList && '404 нет такого боя :('}
        {
          // chatList.length > 0 && battleInfo.party1?.length > 0 &&
          chatList?.map((i, index) => (
            <ChatBody
              key={i?.data?.created + index + i?.data?.id}
              log={{
                dataChat: [i],
                userData: userMain ? userMain : [battleInfo.party2[0]],
              }}
            />
          ))
        }
      </div>
      <div className="battleLog__toLoad">
        <div className={loading ? 'lds-ellipsis' : ''}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default BattleLog;
