import { Response } from '../helper/reqRspName';
import { useDispatch, useStore } from 'react-redux';
import {
  sendToChat,
  sendToChatClear,
  sendToChatHeroList,
  sendToChatOnlineList,
  sendToPrivateChat,
  setChatLeave,
} from '../store/reducer/Slices/SlicesChat';
import { Changer } from './managerGameChanger';
import {
  setAllUserBattleReq,
  setAllUserBattleResults,
  setAllUserBody,
  setAllUserChar,
  setAllUserData,
  setAllUserGroup,
  setAllUserHeroLocation,
  setAllUserInvent,
  setAllUserLevel,
  setAllUserMain,
  setAllUserMainDriverInvite,
  setAllUserMainHeroes,
  setAllUserMainUInfo,
  setAllUserMoney,
  setAllUserNames,
  setAllUserSkills,
  setHeroEffects,
  setHeroUpdatedQuests,
  setHeroCompletedDialogs,
} from '../store/reducer/Slices/SlicesUserData';
import {
  setApplications,
  setApplicationsTime,
} from '../store/reducer/Slices/SlicesApplication';
import {
  setBaseModal,
  setBaseModalData,
} from '../store/reducer/Slices/SliceModal';
import {
  setAction,
  setActionNum,
  setActionProcess,
  setBattleData,
  setBattleParty1,
  setBattleParty2,
  setBattleResult,
  setBattleStatus,
  setEffects,
  setHeroReconCounter,
  setHeroReconData,
  setDebugMsg,
  setMyTotal,
} from '../store/reducer/Slices/SlicesBattle';
import { setLoading } from '../store/reducer/Slices/SlicesLoading';
import {
  setPairCurrent,
  setPairList,
} from '../store/reducer/Slices/SlicesBattleCarrent';
import { setHeroData } from '../store/reducer/Slices/SlicesHeroData';
import { useList } from '../hooks/list/useList';
import { setDataTooltipInfo } from '../store/reducer/Slices/SlicesTooltipInfo';
import { setCheckName } from '../store/reducer/Slices/SlicesCheckName';
import {
  setLevelUpModal,
  setRespawnModal,
  setShopItems,
  setShopPrices,
} from '../store/reducer/Slices/SlicesShop';
import { setOpenMap } from '../store/reducer/Slices/SlicesMap';
import {
  addGroup,
  addInvite,
  resetGroup,
  updateHeroes,
} from '../store/reducer/Slices/SliceGroups';
import useManagerGameError from './managerGameError';
import {
  setChestOpen,
  setCurrentRoom,
  setDungeonLevel,
  setDungeonOpen,
  setDungeonRooms,
  setLoot,
  setRoomUpdate,
} from '../store/reducer/Slices/SlicesDungeon';
import { cookieSet } from '../helper/cookie';

export const useManagerGame = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const setError = useManagerGameError();
  const [toBattleListUpdateAdd, toBattleListUpdate, toBattleListRemove] =
    useList();

  const command = (data, other) => {
    switch (data.$type) {
      case Response.ResponseEmailExists:
        (() => {
          setError.setErrorHandler(data);
        })();
        break;
      case Response.ResponseError:
        (() => {
          setError.setErrorHandler(data);
        })();
        break;
      case Response.ResponseGroupAdd:
        (() => {
          let userMain = store.getState().allUserData.user_main;
          const { toChat } = Changer.toChatGroup(data, userMain, 'GroupAdd');
          dispatch(sendToChat(toChat));
          dispatch(addGroup(data.group));
        })();
        break;
      case Response.ResponseHeroGroupUpdate:
        (() => {
          let userMain = store.getState().allUserData.data;
          const { toChat } = Changer.toChatGroup(data, userMain, 'GroupUpdate');
          dispatch(setAllUserGroup(toChat.data));
          dispatch(sendToChat({ text: toChat.text }));
        })();
        break;
      case Response.ResponseGroupHeroUpdate:
        (() => {
          let groups = store.getState().groups;
          let userMain = store.getState().allUserData.data;
          const { toChat } = Changer.toChatGroup(
            data,
            userMain,
            'GroupHeroUpdate',
            groups,
          );
          const newData = groups.heroGroup.group_heroes.map((oldHero) => {
            if (oldHero.hero_id === data.hero_id) {
              if (oldHero.destination_id !== data.destination_id) {
                console.log('Локация сменилась', data);
                dispatch(
                  sendToChat({
                    text: `Ваш лидер группы начал движение в локацию ${data.destination_id}, как только он придет в нее – вы переместитесь туда же.`,
                  }),
                );
              }
              return Object.assign({}, oldHero, data);
            } else {
              return oldHero;
            }
          });
          dispatch(updateHeroes(newData));
          dispatch(sendToChat({ text: toChat.text }));
        })();
        break;
      case Response.ResponseGroupHeroRemove:
        (() => {
          let userMain = store.getState().allUserData.user_main;
          console.log('data ResponseGroupHeroRemove', data);
          const { toChat } = Changer.toChatGroup(
            data,
            userMain,
            'GroupHeroLeave',
          );
          dispatch(sendToChat(toChat));
          dispatch(resetGroup());
        })();
        break;
      case Response.ResponseGroupInvite:
        (() => {
          dispatch(addInvite(data.group));
        })();
        break;
      case Response.ResponseUserFull:
        (() => {
          dispatch(setAllUserMain(data.user_main));
          dispatch(
            setAllUserData(
              data.heroes.find((e) => e.id === data.user_main.u_info.hero_id),
            ),
          );
          dispatch(setAllUserMainHeroes(data.heroes));
        })();
        break;
      case Response.ResponseHeroLevelUpdate:
        (() => {
          let userData = store.getState().allUserData.data;
          const { result, toChat } = Changer.lvlExp(data, userData, other);
          dispatch(setAllUserLevel(result));

          if (other) {
            dispatch(sendToChat(toChat));
          }

          if (userData.level.level < result.level) {
            // console.log('@@@@data', data);
            // console.log('@@@@userData', userData);
            const msg = Changer.toChat({
              text: `%${userData.h_info.name}&${data.level.id}* RAISED ${userData.h_info.sex === 1 ? '' : 'а'} LEVEL_UP ${result.level}!`,
            });
            const statePromise = () => {
              return new Promise((resolve) => {
                const unsubscribe = store.subscribe(() => {
                  const state = store.getState().baseModal.open;
                  if (state === false) {
                    unsubscribe();
                    resolve(state);
                  }
                });
              });
            };
            // console.log('msg', msg);
            statePromise().then((res) => {
              cookieSet('levelUpModal', JSON.stringify(msg), 99999).then(
                (r) => {
                  dispatch(sendToChat(msg));
                  dispatch(setLevelUpModal(msg));
                  dispatch(setBaseModal(true));
                  dispatch(
                    setBaseModalData({
                      zIndex: 9,
                      numData: 6,
                      styles: 'mini',
                      maxWidth: '300px',
                    }),
                  );
                },
              );
            });
          }
        })();
        break;
      case Response.ResponseHeroMoneyUpdate:
        (() => {
          let userData = store.getState().allUserData.data;
          const { result, toChat } = Changer.toMoney(data, userData, other);
          dispatch(setAllUserMoney(result));
          if (other) {
            dispatch(sendToChat(toChat));
          }
        })();
        break;
      case Response.ResponseUserCurrentInfoUpdate:
        (() => {
          let battleStatus = store.getState().battle.status;
          dispatch(setAllUserMainUInfo(data.u_info));
          if (
            data.u_info?.battle_id === '' &&
            battleStatus &&
            battleStatus === 2
          ) {
            setTimeout(() => {
              dispatch(setBattleStatus(0));
            }, 1500);
          }
        })();
        break;
      case Response.ResponseHeroCharacterUpdate:
        (async () => {
          let userData = store.getState().allUserData.data;
          const { result } = Changer.toCharacters(data, userData);
          await dispatch(setAllUserChar(result));
        })();
        break;
      case Response.ResponseHeroInventoryUpdate:
        (() => {
          let userData = store.getState().allUserData.data;
          const { result } = Changer.toInventoryUpdate(data, userData, other);
          dispatch(setAllUserInvent(result));
        })();
        break;
      case Response.ResponseHeroEquipmentUseUpdate:
        (() => {
          let userData = store.getState().allUserData.data;
          const { result } = Changer.toBodyUpdate(data, userData, other);

          dispatch(setAllUserBody(result));
        })();
        break;
      case Response.ResponseHeroClassWeaponSkillUpdate:
        (() => {
          let userData = store.getState().allUserData.data;
          const { result, toChat } = Changer.toSkillUpdate(
            data,
            userData,
            other,
          );
          dispatch(setAllUserSkills(result));
          if (other) {
            dispatch(sendToChat(toChat));
          }
        })();
        break;
      case Response.ResponseBattleRequests:
        (async () => {
          dispatch(setApplications(data));
        })();
        break;
      case Response.ResponseHeroBattleRequestUpdate:
        (() => {
          dispatch(setAllUserBattleReq(data.h_battle_req));
        })();
        break;
      case Response.ResponseBattleRequestAdd:
        (async () => {
          toBattleListUpdateAdd(data);
        })();
        break;
      case Response.ResponseBattleRequestUpdate:
        (async () => {
          const valTime = Object.values(data)[1].next_status_time;
          dispatch(setApplicationsTime(valTime));

          toBattleListUpdate(data);
        })();
        break;
      case Response.ResponseBattleRequestRemove:
        (async () => {
          toBattleListRemove(data);
        })();
        break;
      case Response.ResponseBattleRequestPvpJoin:
        (() => {
          const { result } = Changer.toBattleModalApplication(
            {
              zIndex: 9,
              title: 'CHALLENGE_ACCEPTED !',
              numData: 1,
            },
            data.info,
          );

          dispatch(setBaseModalData(result));
          dispatch(setBaseModal(true));
        })();
        break;
      case Response.ResponseBattleRequestCancel:
        (() => {
          const { result } = Changer.toBattleModalApplication(
            {
              zIndex: 9,
              title: 'CALL_REFUSED !',
              desc: 'THE_PLAYER_THOUGHT !',
              buttonRed: { text: 'FINE' },
            },
            data,
          );
          dispatch(setBaseModalData(result));
          dispatch(setBaseModal(true));
        })();
        break;
      case Response.ResponseBattleStart:
        (async () => {
          let userMain = store.getState().allUserData.user_main;
          let currentChatData = store.getState().chatData.data;
          if (data.battle.id === userMain.u_info.battle_id) {
            dispatch(setActionProcess([]));
            dispatch(setLoading(true));
            dispatch(setBaseModal(false));
            dispatch(setBaseModalData({}));
            dispatch(setBattleStatus(2));
            dispatch(setActionNum(2));
            console.log('datazxczxczxc', data);
            dispatch(setBattleData(data));
            dispatch(sendToChatClear([{ text: 'THE_FIGHT_BEGINS !' }]));
          } else {
            const duplicate = currentChatData.some(
              (message) =>
                message.type === 22 &&
                message.data.battle.battle_request_id ===
                  data.battle.battle_request_id,
            );
            if (!duplicate) {
              const { toChat } = Changer.toBattleChat(data);
              dispatch(sendToChat(toChat));
              dispatch(setChatLeave(data));
            }
          }
          // const { toChat } = JSON.parse(JSON.stringify(Changer.toBattleChat(data, await data))); // TODO Необходимо пофиксить с хаотическими боями
          // dispatch(sendToChat(toChat))
        })();
        break;
      case Response.ResponseBattlePairCurrent:
        (async () => {
          let statePairCurrent = store.getState().battleCurrent.pairCurrent;

          await dispatch(
            setPairCurrent({
              ...statePairCurrent.pairCurrent,
              ...data,
            }),
          );
          // let pairList = store.getState().battleCurrent.pairList;
          if (data.hero_id) {
            if (data.turn_compl && data.enemy_turn_compl) {
              dispatch(
                setAction({
                  num: 4,
                  data: data,
                }),
              );
            }
            if (data.turn_compl && !data.enemy_turn_compl) {
              dispatch(
                setAction({
                  num: 3,
                  data: data,
                }),
              );
            }
            if (!data.turn_compl) {
              dispatch(
                setAction({
                  num: 1,
                  data: {},
                }),
              );
            }
          } else
            dispatch(
              setAction({
                num: 2,
                data: {},
              }),
            );

          // dispatch(setPairList([...pairList, data]));
        })();
        break;
      case Response.ResponseBattlePairUpdate:
        (async () => {
          let statePairCurrent = store.getState().battleCurrent.pairCurrent;
          if (data.pair_id === statePairCurrent.pair_id) {
            const to = await { ...statePairCurrent, ...data };
            await dispatch(setPairCurrent(to));
          }
          if (data.animation_time) {
            await dispatch(setActionNum(4));
          }
        })();
        break;
      case Response.ResponseBattlePairEnemyTime:
        (() => {
          dispatch(
            setAction({
              num: 3,
              data: data,
            }),
          );
        })();
        break;
      case Response.ResponseBattleHeroUpdate:
        (async () => {
          let userMain = store.getState().allUserData.data;
          console.log('#### 1', data);
          console.log('#### 2', userMain);
          console.log('#### 3', data.hero_id === userMain?.h_info?.id);
          if (data.hero_id === userMain?.h_info?.id) dispatch(setMyTotal(data));
          let battleStart = store.getState().battle.data;
          const { toChat } = JSON.parse(
            JSON.stringify(Changer.toBattleChat(data, await battleStart)),
          );
          const { result } = Changer.toBattleListHp(data, await battleStart);
          if (data.party_id === 1) {
            await dispatch(setBattleParty1(result));
          } else await dispatch(setBattleParty2(result));
          dispatch(sendToChat(toChat));
        })();
        break;
      case Response.ResponseBattleTurnActionResult:
        (async () => {
          console.log('data personal+++++++', data);
          let processData = await store.getState().battle.processData;
          let battleStart = store.getState().battle.data;
          const { toChat } = JSON.parse(
            JSON.stringify(Changer.toBattleChat(data, await battleStart)),
          ); // заготовка для сообщений в чат
          const { fromData } = await Changer.toBattleResultsList(
            await data,
            await processData,
          );

          dispatch(sendToChat(toChat)); // заготовка для сообщений в чат
          let userMain = store.getState().allUserData.user_main;
          if (userMain.u_info.battle_id === '') {
            // dispatch(setActionNum(4))
          }
          await dispatch(setActionProcess(fromData));
          if (userMain.u_info.hero_id !== data.a_her_id) {
            await dispatch(
              setEffects({ effects: data.effects, hero_id: data.a_her_id }),
            );
          }
        })();
        break;
      case Response.ResponseBattleHeroDead:
        (async () => {
          const { toChat } = Changer.toBattleChat(data);
          let currentChatData = store.getState().chatData.data;
          const duplicate = currentChatData.some(
            (message) =>
              message.type === 26 &&
              message.data.hero_id === data.hero_id &&
              message.data.created === data.created,
          );
          if (!duplicate) {
            setTimeout(async () => {
              dispatch(sendToChat(toChat));
              dispatch(setRespawnModal(data.hero_id));
            }, 30);
            dispatch(setChatLeave(data));
          }
        })();
        break;
      case Response.ResponseHeroBattleResultAdd:
        (async () => {
          let BattleResults = store.getState().allUserData.data.battle_results;
          let userMain = store.getState().allUserData.user_main;
          await dispatch(
            setAllUserBattleResults([
              ...(await BattleResults),
              data.battle_result,
            ]),
          );
          if (userMain.u_info.battle_id === '') {
            //     if (data.battle_result.battle_id === userMain.u_info.battle_id) {
            await dispatch(setBattleResult(data));
            setTimeout(async () => {
              await dispatch(setBattleStatus(3));
            }, 300);
            await dispatch(setBaseModal(true));
            await dispatch(
              setBaseModalData({
                zIndex: 9,
                numData: 4,
                styles: 'mini',
                maxWidth: '300px',
              }),
            );
            //     }
          }
          if (
            !data.battle_result.is_win &&
            data.battle_result.battle_type === 6
          ) {
            dispatch(setRespawnModal(data.battle_result.hero_id));
          }
          dispatch(setHeroReconData({}));
          dispatch(setHeroReconCounter(0));
          dispatch(setEffects([]));
        })();
        break;
      case Response.ResponseHeroBattleResultRemove:
        (async () => {
          let BattleResults = store.getState().allUserData.data.battle_results;
          const { result } = Changer.toBattleResultsRemove(
            data,
            await BattleResults,
          );
          await dispatch(setAllUserBattleResults(result));
          if (!data.battle_result.is_win) {
            dispatch(setDungeonOpen(false));
          }
        })();
        break;
      case Response.ResponseBattleEnd:
        (async () => {
          let userMain = store.getState().allUserData.user_main;
          if (data.battle.id === userMain.u_info.battle_id) {
            await dispatch(setBattleStatus(3));
            dispatch(setMyTotal({}));
          } else {
            setTimeout(async () => {
              let currentChatData = await store.getState().chatData.data;
              const duplicate = currentChatData.some(
                (message) =>
                  message.type === 33 && message.data.created === data.created,
              );
              if (!duplicate) {
                const { toChat } = Changer.toBattleChat(data);
                dispatch(sendToChat(toChat));
                dispatch(setChatLeave(data));
              }
            }, 50);
          }

          // await dispatch(sendToChat([]));
          // if (data.battle_id === userMain.u_info.battle_id) {
          //     await dispatch(setBattleStatus(3))
          // }
        })();
        break;
      case Response.ResponseBattleTurnResultSpell:
        (async () => {
          // let processData = await store.getState().battle.processData;
          let battleStart = store.getState().battle.data;
          const { toChat } = JSON.parse(
            JSON.stringify(Changer.toBattleChat(data, await battleStart)),
          ); // заготовка для сообщений в чат
          // const { fromData } = await Changer.toBattleResultsList(await data, await processData);
          console.log('ResponseBattleTurnResultSpell toChat', toChat);
          dispatch(sendToChat(toChat));
        })();
        break;
      case Response.ResponseChatMessageText:
        (async () => {
          console.log('data', data);
          const result = Changer.toChat(data);
          dispatch(sendToChat(result));
        })();
        break;
      case Response.ResponseChatMessageOnline:
        (async () => {
          let onlineList = store.getState().chatData.onlineList;
          const checkOnlineList = onlineList.find(
            (e) => e.hero_id === data.hero.hero_id,
          );
          if (checkOnlineList === undefined)
            dispatch(sendToChatOnlineList([...onlineList, data.hero]));
        })();
        break;
      case Response.ResponseChatMessageOffline:
        (async () => {
          let onlineList = store.getState().chatData.onlineList;
          const delHero = onlineList.filter((e) => e.user_id !== data.user_id);
          dispatch(sendToChatOnlineList(delHero));
        })();
        break;
      case Response.ResponseChatOnlineList:
        (async () => {
          dispatch(sendToChatOnlineList(data.heroes));
        })();
        break;
      case Response.ResponseChatHeroList:
        (async () => {
          dispatch(sendToChatHeroList(data.heroes));
        })();
        break;
      case Response.ResponseChatMessagePersonalText:
        (async () => {
          let userData = store.getState().allUserData.data;
          const result = Changer.toChat(data);
          const isUserHeroReceiver = data.receivers.some(
            (reciver) => reciver.hero_id === userData.id,
          );

          if (userData.id === data.hero.hero_id || isUserHeroReceiver) {
            const heroNames = result.text.match(/[^%]+(?=\*)/g);
            if (heroNames) {
              heroNames.forEach((name) => {
                result.text = result.text.replace(`%${name}* `, '');
              });
            }
            dispatch(sendToChat(result));
            dispatch(sendToPrivateChat(result));
          }
        })();
        break;
      case Response.ResponseHeroInfo:
        (async () => {
          dispatch(setHeroData(data.hero_info));
        })();
        break;
      case Response.ResponseBattlePairAdd:
        (async () => {
          let pairList = store.getState().battleCurrent.pairList;
          if (pairList.length > 0) {
            dispatch(setPairList([...pairList, data]));
          } else dispatch(setPairList([data]));
        })();
        break;
      case Response.ResponseGeneratedName:
        (async () => {
          dispatch(setAllUserNames(data.names));
        })();
        break;
      default:
        break;
      case Response.ResponseChatEquipmentUp:
        (async () => {
          let userData = store.getState().allUserData.data;
          // const msg = Changer.toChat({ text: `%${userData.h_info.name}&${userData.id}* UP ${userData.h_info.sex === 1 ? "CHAT_IS" : "CHAT_ER"} LEVEL ${data.dict_id}` });
          const msg = {
            hero: { name: userData.h_info.name },
            text: `UP ${userData.h_info.sex === 1 ? 'CHAT_IS' : 'CHAT_ER'} LEVEL #${data.dict_id}*`,
          };
          dispatch(sendToChat(msg));
        })();
        break;
      case Response.ResponseChatEquipmentChange:
        (async () => {
          let userData = store.getState().allUserData.data;
          if (data.dict_id) {
            // const msg = Changer.toChat({ text: `%${userData.h_info.name}&${userData.id}* EQUIPPED ${userData.h_info.sex === 1 ? "CHAT_IS" : "CHAT_ER"} ${data.dict_id}` });
            const msg = {
              hero: { name: userData.h_info.name },
              text: `EQUIPPED ${userData.h_info.sex === 1 ? 'CHAT_IS' : 'CHAT_ER'} #${data.dict_id}*`,
            };
            dispatch(sendToChat(msg));
          }
        })();
        break;
      case Response.ResponseHeroCharacterInfos:
        (async () => {
          dispatch(setDataTooltipInfo(data.infos));
        })();
        break;
      case Response.ResponseUseItemResult:
        (async () => {
          console.log('ResponseUseItemResult', data);
          const msg = {
            hero: { name: data.custer.name },
            text: `${data.result === 1 ? 'SUCCESS_USE' : 'NOT_SUCCESS_USE'} TO_USE ${data.custer.sex === 1 ? 'CHAT_IS' : 'CHAT_ER'} SPELL ${data.spell_id} ${data.hp > 0 ? '(' + data.hp + ')' : ''} ${data.a_damag > 0 ? '(' + data.a_damag + ')' : ''}`,
          };
          dispatch(sendToChat(msg));
        })();
        break;
      case Response.ResponseHeroNameExists:
        (async () => {
          dispatch(setCheckName(data));
        })();
        break;
      case Response.ResponseHeroDriverUpdate:
        (async () => {
          let userHeroes = store.getState().allUserData.heroes;
          const fromResult = Changer.toHeroDriverUpdate(userHeroes, data);
          console.log('fromResult', fromResult);
          dispatch(setAllUserMainHeroes(fromResult));
        })();
        break;
      case Response.ResponseDriverHeroRemoved:
        (async () => {
          let userHeroes = store.getState().allUserData.heroes;
          const fromResult = Changer.toHeroDriverDel(userHeroes, data);
          dispatch(setAllUserMainHeroes(fromResult));
        })();
        break;
      case Response.ResponseDriverInviteCreated:
        (async () => {
          let userHeroes = store.getState().allUserData.heroes;
          console.log('ResponseDriverInviteCreated data', data);
          dispatch(setAllUserMainHeroes([...userHeroes, data]));
          dispatch(setAllUserMainDriverInvite({ hero_id: data.id }));
        })();
        break;
      case Response.ResponseTakeApartResult:
        (async () => {
          dispatch(setBaseModalData(data));
        })();
        break;
      case Response.ResponseBuyerListItems:
        (async () => {
          dispatch(setShopItems(data));
        })();
        break;
      case Response.ResponseHeroLocationUpdate:
        (async () => {
          let onlineList = store.getState().allUserData.data.h_location;
          console.log(
            'store.getState().allUserData',
            store.getState().allUserData,
          );
          console.log('onlineList', onlineList);
          console.log('data', data);
          const newHLocation = { ...onlineList, ...data.h_location };
          dispatch(setAllUserHeroLocation(newHLocation));

          if (onlineList.location_id !== data.h_location.location_id) {
            dispatch(setOpenMap(false));
          }

          // if (data.h_location.random_event_id) {
          //   dispatch(setEvent(data.h_location.random_event_id));
          //   dispatch(setPause(true));
          // } else if (data.h_location.random_event_id === '') {
          //   dispatch(setEvent(''));
          //   dispatch(setPause(false));
          // }
        })();
        break;
      case Response.ResponseBuyerItemPrice:
        (async () => {
          dispatch(setShopPrices(data));
        })();
        break;
      case Response.ResponseDungeonRooms:
        (async () => {
          dispatch(setDungeonLevel(data.level));
          dispatch(setDungeonRooms(data.rooms));
        })();
        break;
      case Response.ResponseDungeonRoomModelUpdate:
        (async () => {
          const rooms = store.getState().DungeonsData.rooms;
          const result = await rooms.map((el) => {
            if (el.id === data.dungeon_room.id) {
              return data.dungeon_room;
            }
            return el;
          });
          dispatch(setDungeonRooms(result));
        })();
        break;
      case Response.ResponseDungeonLootReceive:
        (async () => {
          dispatch(setLoot(data.loot));
        })();
        break;
      case Response.ResponseHeroBattleResultUpdate:
        (async () => {
          let BattleResults = store.getState().allUserData.data.battle_results;
          await dispatch(
            setAllUserBattleResults([
              ...(await BattleResults),
              data.battle_result,
            ]),
          );
        })();
        break;
      case Response.ResponseDungeonOpenChestFail:
        (async () => {
          dispatch(setChestOpen(data));
        })();
        break;
      case Response.ResponseHeroActiveSlots:
        (async () => {
          dispatch(setHeroReconData(data));
          dispatch(setHeroReconCounter(5));
        })();
        break;
      case Response.ResponseHeroQuestsUpdate:
        (async () => {
          let quests = store.getState().allUserData.data.h_quests.quests;
          if (data.rmv && data.rmv.length > 0) {
            let rmvQst = quests.filter((quest) => !data.rmv.includes(quest.id));
            dispatch(setHeroUpdatedQuests(rmvQst));
          } else {
            let haveQ =
              quests.length > 0
                ? quests.filter((el) => el.id === data.quests[0]?.id)
                : [];
            if (data.quests[0] && haveQ.length === 0) {
              dispatch(setHeroUpdatedQuests([...quests, data.quests[0]]));
            } else if (
              haveQ.length > 0 &&
              data.quests[0].completed &&
              !haveQ[0].completed
            ) {
              let updatedQuests = quests.map((quest) =>
                quest.id === data.quests[0].id
                  ? { ...quest, completed: data.quests[0].completed }
                  : quest,
              );
              dispatch(setHeroUpdatedQuests(updatedQuests));
            } else if (data?.completed_dialogs?.length > 0) {
              dispatch(setHeroCompletedDialogs(data.completed_dialogs));
            }
          }
        })();
        break;
      case Response.ResponseHeroEffectsUpdate:
        (async () => {
          dispatch(setHeroEffects(data.h_effects.effects));
          console.log('ResponseHeroEffectsUpdate------!!!', data.h_effects);
        })();
        break;
      case Response.ResponseChatMessageLocationLeave:
        (async () => {
          dispatch(setChatLeave(data));
        })();
        break;
      case Response.ResponseBattleHeroAdd:
        (async () => {
          let battleStart = store.getState().battle.data;
          const { party_id, hero_id } = data.battle_her_inf;

          const party =
            party_id === 1 ? battleStart.party1 : battleStart.party2;
          const setBattleParty =
            party_id === 1 ? setBattleParty1 : setBattleParty2;
          const heroExists = party.some((hero) => hero.hero_id === hero_id);

          if (!heroExists) {
            await dispatch(setBattleParty([...party, data.battle_her_inf]));
          }
        })();
        break;
      case Response.ResponseBattleLogs:
        (async () => {
          let result = [];
          let game = true;
          for (let i = 0; i < data.logs.length; i++) {
            const { toChat } = await Changer.toBattleChat(
              data.logs[i],
              data.logs[0],
              game,
            );
            result.push(toChat);
          }
          dispatch(sendToChatClear(result.reverse()));
          // console.log('toChat--result', result);
        })();
        break;
      case Response.ResponseDebugMsg:
        (async () => {
          // let debugMsg = store.getState().battle.debug_msg;
          // await dispatch(setDebugMsg([...(await debugMsg), data.log]));
          // для тестов, потом убрать
          // const processLog = (log) => {
          //   const ids = new Set();
          //   let parts = log.split(' ');
          //   for (let i = 0; i < parts.length; i++) {
          //     if (parts[i].includes('$')) {
          //       const [name, id] = parts[i].split('$');
          //       parts[i] = name;
          //       if (ids.has(id)) {
          //         parts.splice(i + 1, 1);
          //       } else {
          //         ids.add(id);
          //         parts.splice(i + 1, 0, id);
          //       }
          //     }
          //     if (parts[i].toLowerCase() === 'id' && ids.has(parts[i + 1])) {
          //       parts.splice(i, 2);
          //       i -= 2;
          //     } else if (parts[i].toLowerCase() === 'id') {
          //       ids.add(parts[i + 1]);
          //     }
          //   }
          //   const processedLog = parts
          //     .join(' ')
          //     .replace(/defender/gi, '|| defender')
          //     .replace(/attacker/gi, '|| attacker');
          //   return processedLog;
          // };
          // const result = processLog(data.log);
          // dispatch(sendToPrivateChat({ text: result }));
        })();
        break;
    }
  };

  return [command];
};
