export const RequestCommand = {
  RequestOnlineChanel: 1,
  RequestEmailExists: 2,
  RequestRegisterByEmail: 3,
  RequestAuthByEmail: 4,
  RequestAuthByJwt: 5,
  RequestEquipmentOn: 6,
  RequestCheatAddExperience: 7,
  RequestCheatAddItem: 8,
  RequestCheatAddEquipmentPoints: 9,
  RequestCheatAddMoney: 10,
  RequestCheatAddWeaponSkill: 11,
  RequestCheatReset: 12,
  RequestCharacterUpdate: 13,
  RequestEquipmentOff: 14,
  RequestUserOnline: 15,
  RequestBattleRequestList: 16,
  RequestBattleRequestCreate: 17,
  RequestBattleRequestsUnsubscribe: 18,
  RequestBattleRequestJoin: 19,
  RequestBattleRequestsCancel: 20,
  RequestBattleRequestConfirmPvp: 21,
  RequestHeroInfo: 22,
  RequestBattleRequestCancelPvp: 23,
  RequestBattleTurn: 24,
  RequestBattleData: 25,
  RequestBattleResultGet: 26,
  RequestBattleAutoTurn: 27,
  RequestCheatAddToInventory: 28,
  RequestActiveSlotOn: 29,
  RequestActiveSlotOff: 30,
  RequestBattleChangePair: 31,
  RequestOperationalSlotOn: 32,
  RequestOperationalSlotOff: 33,
  RequestEquipmentRuneAdd: 34,
  RequestCheatFillItems: 35,
  RequestEquipmentRuneJoin: 36,
  RequestChatText: 37,
  RequestChatOnlineList: 38,
  RequestChatPersonalText: 39,
  RequestChatFindHero: 40,
  RequestChatTextAllUsers: 41,
  RequestChangePassword: 42,
  RequestUnsetHero: 43,
  RequestSetHero: 44,
  RequestCreateHero: 45,
  RequestGetBattleHistoryLength: 46,
  RequestGetBattleLogs: 47,
  RequestOperationalSlotChange: 48,
  RequestHeroRebirth: 49,
  RequestTakeApart: 50,
  RequestBattleRequestPvpBot: 51,
  RequestGenerateName: 52,
  RequestCharactersInfo: 53,
  RequestResetPassword: 54,
  RequestHeroNameExists: 55,
  RequestInventoryUse: 56,
  RequestDriverInvite: 57,
  RequestDriverInviteAccept: 58,
  RequestDriverCancel: 59,
  RequestDriverCancelByOwner: 60,
  RequestInventoryItemDelete: 61,
  RequestLocationMove: 62,
  RequestBuildingMove: 63,
  RequestAltarVictims: 64,
  RequestMoneyExchange: 65,
  RequestBuyerBuyItem: 66,
  RequestBuyerGetItemPrice: 67,
  RequestBuyerSellItem: 68,
  RequestBuyerListItems: 69,
  RequestNpcBuyItem: 70,
  RequestAuctionListItems: 71,
  RequestAuctionAddItem: 72,
  RequestAuctionCancelItem: 73,
  RequestAuctionBetItem: 74,
  RequestVacationStart: 75,
  RequestVacationStop: 76,
  RequestGroupCreate: 77,
  RequestGroupInvite: 78,
  RequestGroupInviteProcess: 79,
  RequestGroupHeroKick: 80,
  RequestGroupHeroLeave: 81,
  RequestGroupGetInfo: 82,
  RequestPvpFreeCreate: 83,
  RequestPvpFreeJoin: 84,
  RequestDungeonEnter: 85,
  RequestDungeonMove: 86,
  RequestDungeonChestOpen: 88,
  RequestDungeonGetLoot: 89,
  RequestDungeonDoorOpen: 90,
  RequestDungeonDoorUse: 91,
  RequestDungeonRooms: 92,
  RequestQuestsList: 94,
  RequestQuestAccept: 95,
  RequestQuestComplete: 96,
  RequestRandomEventLeave: 97,
  RequestRandomEventBattle: 98,
  RequestQuestNpcVisit: 99,
  RequestCreateMark: 100,
  RequestBeginResetPassword: 101,
  RequestCheatZodiac: 102,
  RequestBookUse: 103,
  RequestBattleElixirUse: 104,
  RequestNpcDialogComplete: 105,
};

export const RequestBattleType = {
  1: 'bat_req_pvp',
  2: 'bat_req_group',
  3: 'bat_req_random',
  4: 'bat_req_himself',
};

export const RequestBattleTypeInvert = {
  bat_req_pvp: 1,
  bat_req_group: 2,
  bat_req_random: 3,
  bat_req_himself: 4,
};

export const ResponseLogin = {
  NotExists: 1,
  Exists: 2,
  Blocked: 3,
  TestersOnly: 4,
};

export const ResponseWarning = {
  EmailExists: 1,
  HeroNameExists: 2,
  WrongPassword: 3,
  WrongJwt: 4,
};

export const GroupInviteProcessType = {
  Unknown: 0,
  Accept: 1,
  Decline: 2,
  Ban: 3,
};

export const EffectType = {
  1: 'Stun',
  2: 'Knockdown',
  3: 'Paralysis',
  4: 'Blindness',
  5: 'Silence',
  6: 'DefensePvP',
  7: 'HazeChaos',
  8: 'ShowEnemyData',
  9: 'Characters',
  10: 'DamageDefence',
  11: 'Poisoning',
  12: 'StealthMode',
  13: 'DamageDefenceCreator',
  14: 'NoRequirements',
  15: 'MarkDark',
  16: 'MarkLight',
  17: 'MarkChaos',
  18: 'MarkCreator',
};

export const Response = {
  ResponseBattleID: 777,
  ResponseEmailExists: 1,
  ResponseWarning: 2,
  ResponseUserFull: 3,
  ResponseError: 4,
  ResponseUserRoleUpdate: 5,
  ResponseHeroLevelUpdate: 6,
  ResponseHeroInventoryUpdate: 7,
  ResponseHeroInfoUpdate: 8,
  ResponseHeroEquipmentUseUpdate: 9,
  ResponseHeroClassWeaponSkillUpdate: 10,
  ResponseHeroCharacterUpdate: 11,
  ResponseHeroMoneyUpdate: 12,
  ResponseUserCurrentInfoUpdate: 13,
  ResponseHeroLocationUpdate: 14,
  ResponseHeroBattleRequestUpdate: 15,
  ResponseBattleRequests: 16,
  ResponseBattleRequestAdd: 17,
  ResponseBattleRequestUpdate: 18,
  ResponseBattleRequestRemove: 19,
  ResponseBattleRequestCancel: 20,
  ResponseBattleRequestPvpJoin: 21,
  ResponseBattleStart: 22,
  ResponseBattlePairCurrent: 23,
  ResponseBattlePairAdd: 24,
  ResponseBattleTurnActionResult: 25,
  ResponseBattleHeroDead: 26,
  ResponseBattlePairUpdate: 27,
  ResponseBattlePairsRemove: 28,
  ResponseBattlePairEnemyTime: 29,
  ResponseHeroBattleResultAdd: 30,
  ResponseHeroBattleResultRemove: 31,
  ResponseBattleHeroUpdate: 32,
  ResponseBattleEnd: 33,
  ResponseHeroEffectsUpdate: 34,
  ResponseBattleTurnResultSpell: 35,
  ResponseChatMessageOnline: 36,
  ResponseChatMessageOffline: 37,
  ResponseChatOnlineList: 38,
  ResponseChatMessageText: 39,
  ResponseChatMessagePersonalText: 40,
  ResponseChatEquipmentUp: 49,
  ResponseChatEquipmentChange: 50,
  ResponseHeroChatUpdate: 41,
  ResponseChatHeroList: 42,
  ResponseHeroInfo: 43,
  ResponseBattleHistoryLength: 44,
  ResponseHeroStatisticsUpdate: 45,
  ResponseTakeApartResult: 46,
  ResponseGeneratedName: 47,
  ResponseHeroCharacterInfos: 51,
  ResponseHeroNameExists: 52,
  ResponseUseItemResult: 53,
  ResponseHeroDriverUpdate: 54,
  ResponseDriverPassword: 55,
  ResponseDriverInviteCreated: 56,
  ResponseDriverHeroRemoved: 57,
  ResponseGroupAdd: 58,
  ResponseChatMessageLocationLeave: 59,
  ResponseHeroScalpsUpdate: 60,
  ResponseBuyerItemPrice: 61,
  ResponseBuyerListItems: 62,
  ResponseAuctionItemUpdate: 63,
  ResponseAuctionListItems: 64,
  ResponseAuctionItemRemove: 65,
  ResponseHeroAuctionBetsUpdate: 66,
  ResponseGroupHeroAdd: 67,
  ResponseGroupInvite: 68,
  ResponseGroupHeroRemove: 69,
  ResponseHeroGroupUpdate: 70,
  ResponseGroupUpdateLocation: 71,
  ResponseGroupHeroUpdate: 72,
  ResponseGroupUpdateCreator: 73,
  ResponseBattleApplyAbilityResult: 74,
  ResponseBattleHeroAdd: 75,
  ResponseDungeonRooms: 76,
  ResponseDungeonRoomModelUpdate: 77,
  ResponseDungeonOpenChestFail: 78,
  ResponseDungeonLootReceive: 79,
  ResponseHeroBattleResultUpdate: 80,
  ResponseHeroQuestsUpdate: 81,
  ResponseQuestsList: 82,
  ResponseHeroActiveSlots: 83,
  ResponseBattleLogs: 84,
  ResponseDebugMsg: 85,
};
