import { memo, useEffect, useRef, useState } from 'react';
import NewWindow from 'react-new-window';
import Chat from './Chat';

const params = {
  width: 700,
  height: 400,
};

const ChatWindow = ({ openWindowChatBtnRef }) => {
  const [isOpenWindowChat, setIsOpenWindowChat] = useState(false);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (openWindowChatBtnRef?.current) {
      openWindowChatBtnRef.current.onclick = handleOpenWindowChat;
    }
  }, []);

  useEffect(() => {
    if (isOpenWindowChat) {
      document.documentElement.classList.add('chatWindowOpen');
    } else {
      document.documentElement.classList.remove('chatWindowOpen');
    }
  }, [isOpenWindowChat]);

  const handleOpenWindowChat = () => {
    if (chatWindowRef?.current) {
      chatWindowRef.current.focus();
      return;
    }

    setIsOpenWindowChat(true);
  };

  const closeWindowChat = () => {
    setIsOpenWindowChat(false);
    chatWindowRef.current = null;
  };

  const getRefWindowChat = (ref) => {
    chatWindowRef.current = ref;
  };

  if (!isOpenWindowChat) {
    return null;
  }

  return (
    <NewWindow
      title="Sacralium-chat"
      onUnload={closeWindowChat}
      onOpen={getRefWindowChat}
      features={params}
    >
      <div className="playingFieldFooter playingFieldFooter--window">
        <Chat showBtnOpenWindowChat={false} btnStyle={true} />
      </div>
    </NewWindow>
  );
};

export default memo(ChatWindow);
