export default function checkRead(
  message,
  readIds,
  allMessages,
  userQuests,
  completedQuests,
  npc,
) {
  if (readIds.length > 0 && !readIds.includes(message.id)) {
    return false;
  }

  const children = allMessages.filter((msg) => msg.parent_id === message.id);

  if (children.length === 0) {
    return true;
  }

  for (const child of children) {
    if (child.action === 'dialogue') {
      if (child?.condition === 'done_quest') {
        const doneQ = userQuests.filter((el) => el.completed);
        let npcQ = new Set(npc?.quest_ids);
        let hasMatch = doneQ.some((item) => npcQ.has(item.dict_id));

        if (doneQ.length > 0 && hasMatch) {
          if (!readIds.includes(child.id)) {
            return false;
          }
          if (
            !checkRead(
              child,
              readIds,
              allMessages,
              userQuests,
              completedQuests,
              npc,
            )
          ) {
            return false;
          }
        } else {
          return true;
        }
      }

      if (child?.condition === 'have_quest') {
        let npcQ2 = new Set(npc?.quest_ids);
        let hasMatch2 = userQuests.some((item) => npcQ2.has(item.dict_id));

        if (userQuests.length > 0 && hasMatch2) {
          if (!readIds.includes(child.id)) {
            return false;
          }
          if (
            !checkRead(
              child,
              readIds,
              allMessages,
              userQuests,
              completedQuests,
              npc,
            )
          ) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (child.condition && child.condition.startsWith('done.')) {
        let qstDone;

        if (child.condition.includes(',')) {
          const conditionQsts = child.condition.split(',');
          qstDone = conditionQsts.every((el) => {
            const [, qstNum, num] = el.split('.');
            return completedQuests.some((qst) => qst === `${qstNum}.${num}`);
          });
        } else {
          const [, qstNum, num] = child.condition.split('.');
          qstDone = completedQuests.some((qst) => qst === `${qstNum}.${num}`);
        }

        if (qstDone) {
          if (!readIds.includes(child.id)) {
            return false;
          }
          if (
            !checkRead(
              child,
              readIds,
              allMessages,
              userQuests,
              completedQuests,
              npc,
            )
          ) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (!child.condition) {
        if (!readIds.includes(child.id)) {
          return false;
        }
        if (
          !checkRead(
            child,
            readIds,
            allMessages,
            userQuests,
            completedQuests,
            npc,
          )
        ) {
          return false;
        }
      }
    }
  }

  return true;
}
