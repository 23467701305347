import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDungeonOpen,
  setEnterModal,
} from '../../../store/reducer/Slices/SlicesDungeon';
import { useRequest } from '../../../hooks/useRequest';
import Lang from '../../language/index';
import '../styles.scss';

function DungeonEnter() {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const building = useSelector(
    (state) => state.DungeonsData.enterModal.building_id,
  );

  const handleEnter = () => {
    requestSend.sendData('RequestBuildingMove', {
      build_id: building,
    });
    requestSend
      .sendData('RequestDungeonEnter', {
        level: 1,
      })
      .then((res) => {
        if (res[0].$type === 14) {
          requestSend.sendData('RequestDungeonRooms', {});
          dispatch(
            setEnterModal({
              open: false,
              building_id: building,
            }),
          );
          dispatch(setDungeonOpen(true));
        } else {
          requestSend.sendData('RequestBuildingMove', {
            build_id: building,
          });
          dispatch(
            setEnterModal({
              open: false,
              building_id: null,
            }),
          );
        }
      });
  };

  const handleRefuse = () => {
    dispatch(
      setEnterModal({
        open: false,
        building_id: null,
      }),
    );
  };

  return (
    <div className="dungeon_modal_base--enter">
      <p>
        <Lang>Вход в подземелье. Желаете войти?</Lang>
      </p>
      <span
        className="dungeon_modal_base--enter--response"
        onClick={() => handleEnter()}
      >
        {' '}
        <Lang>- Войти</Lang>
      </span>
      <span
        className="dungeon_modal_base--enter--response"
        onClick={() => handleRefuse()}
      >
        {' '}
        <Lang>- Не входить</Lang>
      </span>
    </div>
  );
}

export default DungeonEnter;
