import React, { useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setDungeonOpen } from '../../store/reducer/Slices/SlicesDungeon';
import DungeonRooms from './DungeonRooms';
import ModalResp from '../modal/ModalRespawn/ModalResp';

function DungeonBase() {
  const dispatch = useDispatch();
  const userLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const battleStatus = useSelector((state) => state.battle.status);

  useEffect(() => {
    if (battleStatus === 1) {
      dispatch(setDungeonOpen(false));
    }

    if (battleStatus === 3 && userLocation.location_id.startsWith('dng')) {
      dispatch(setDungeonOpen(true));
    }
  }, []);

  return (
    <>
      <ModalResp />
      <div className="dungeon_container">
        <DungeonRooms userLocation={userLocation} />
      </div>
    </>
  );
}

export default DungeonBase;
