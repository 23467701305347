import HeroNameLink from '../../HeroNameLink/HeroNameLink';
import Lang from '../../../language';
import React from 'react';
import Abil from '../Abil';

export default function Templ3({
  isUser,
  message,
  msgDamageSize,
  msgAttackZone,
  dmg,
}) {
  return (
    <div className="toChat">
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_BEGAN</Lang>
      {message?.a_her?.isGenderMan ? (
        <Lang>CHAT_IS</Lang>
      ) : (
        <Lang>CHAT_ER</Lang>
      )}{' '}
      <Lang>CHAT_ATTACK</Lang>,{' '}
      <HeroNameLink userHero={message?.d_her?.hero_id} isUser={!isUser}>
        {message?.d_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_MISSED</Lang>
      {message?.a_her?.isGenderMan ? (
        <Lang>CHAT_IS</Lang>
      ) : (
        <Lang>CHAT_ER</Lang>
      )}{' '}
      <Lang>CHAT_MOMENT_AND</Lang> <Lang>{msgDamageSize}</Lang>{' '}
      <Lang>CHAT_MISSED_IT</Lang>
      {message?.a_her?.isGenderMan ? (
        <Lang>CHAT_IS</Lang>
      ) : (
        <Lang>CHAT_ER</Lang>
      )}{' '}
      <Lang>{msgAttackZone}</Lang>{' '}
      <span className="chat__block1--body--message--item--text--hp">
        {' '}
        -{dmg} [{message?.data?.d_hp}/{message.d_her.max_hp}]
      </span>
      <Abil message={message} />
    </div>
  );
}
