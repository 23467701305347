import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import imgMap from '../../media/img/game/playingField/dungeons/MapFull.png';
import imgActive from '../../media/img/game/playingField/dungeons/flag.png';
import imgChst from '../../media/img/game/playingField/dungeons/chest.png';
import imgFloor from '../../media/img/game/playingField/dungeons/steps.png';
// import imgMob from '../../media/img/game/playingField/dungeons/boss.png';
import imgDoor from '../../media/img/game/playingField/dungeons/door.png';
import imgDone from '../../media/img/game/playingField/dungeons/check.png';
import { useRequest } from '../../../src/hooks/useRequest';
import DungeonModal from './DungeonModal';
import _ from 'lodash';

function DungeonRooms({ userLocation }) {
  const [requestSend] = useRequest();
  const userLocationData = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const dungeonRooms = useSelector((state) => state.DungeonsData.rooms);
  const [activeCell, setActiveCell] = useState(
    `${userLocation.dungeon_y}-${userLocation.dungeon_x}` || null,
  );
  const [activeRoom, setActiveRoom] = useState(null);
  const [level, setLevel] = useState(userLocationData.dungeon_level || 1);

  useEffect(() => {
    if (dungeonRooms.length > 0) {
      const nowRoom = dungeonRooms.filter(
        (el) =>
          el.y === userLocationData.dungeon_y &&
          el.x === userLocationData.dungeon_x,
      );
      setActiveRoom(nowRoom[0]);
      setActiveCell(
        `${userLocationData.dungeon_y}-${userLocationData.dungeon_x}`,
      );
      setLevel(dungeonRooms[0]?.level);
    } else {
      requestSend.sendData('RequestDungeonRooms', {});
    }
  }, [dungeonRooms, userLocationData]);

  const roomMatrix = Array.from({ length: 15 }, () =>
    Array.from({ length: 15 }, () => false),
  );

  dungeonRooms?.forEach((room) => {
    const { x, y } = room;
    if (x >= 0 && x < 15 && y >= 0 && y < 15) {
      roomMatrix[y][x] = room;
    }
  });

  const calculateAvailableDirections = (
    rowIndex,
    colIndex,
    roomMatrix,
    room,
  ) => {
    const availableDirections = [];
    if (colIndex > 0 && roomMatrix[rowIndex][colIndex - 1]) {
      availableDirections.push('left');
    }
    if (rowIndex > 0 && roomMatrix[rowIndex - 1][colIndex]) {
      availableDirections.push('up');
    }
    if (
      colIndex < roomMatrix[rowIndex].length - 1 &&
      roomMatrix[rowIndex][colIndex + 1]
    ) {
      availableDirections.push('right');
    }
    if (
      rowIndex < roomMatrix.length - 1 &&
      roomMatrix[rowIndex + 1][colIndex]
    ) {
      availableDirections.push('down');
    }
    const { move_directions } = room;
    if (move_directions && move_directions.length > 0) {
      if (!move_directions.includes(1)) {
        const index = availableDirections.indexOf('up');
        if (index !== -1) availableDirections.splice(index, 1);
      }
      if (!move_directions.includes(2)) {
        const index = availableDirections.indexOf('down');
        if (index !== -1) availableDirections.splice(index, 1);
      }
      if (!move_directions.includes(3)) {
        const index = availableDirections.indexOf('left');
        if (index !== -1) availableDirections.splice(index, 1);
      }
      if (!move_directions.includes(4)) {
        const index = availableDirections.indexOf('right');
        if (index !== -1) availableDirections.splice(index, 1);
      }
    }
    return availableDirections;
  };

  const handleCellClick = (x, y, room, nowCell, nowRoom) => {
    if (room && !(activeRoom?.mob_keys && activeRoom?.mob_keys.length > 0)) {
      let nowDirection = 0;
      const [activeX, activeY] = activeCell
        .split('-')
        .map((coord) => parseInt(coord));
      if (
        (Math.abs(x - activeX) === 1 && y === activeY) ||
        (Math.abs(y - activeY) === 1 && x === activeX)
      ) {
        if (activeCell !== `${x}-${y}`) {
          if (
            y < activeY &&
            (activeRoom.move_directions.length === 0 ||
              activeRoom.move_directions.includes(3))
          ) {
            // влево
            nowDirection = 3;
          } else if (
            y > activeY &&
            (activeRoom.move_directions.length === 0 ||
              activeRoom.move_directions.includes(4))
          ) {
            // вправо
            nowDirection = 4;
          } else if (
            x < activeX &&
            (activeRoom.move_directions.length === 0 ||
              activeRoom.move_directions.includes(1))
          ) {
            // вверх
            nowDirection = 1;
          } else if (
            x > activeX &&
            (activeRoom.move_directions.length === 0 ||
              activeRoom.move_directions.includes(2))
          ) {
            // вниз
            nowDirection = 2;
          }
        }
      }

      if (nowDirection !== 0) {
        // setActiveRoom(room);
        // setActiveCell(`${x}-${y}`);
        requestSend
          .sendData('RequestDungeonMove', {
            move: nowDirection,
          })
          .then((res) => {
            if (res[0].$type === 2 || res[0].$type === 4) {
              const [x, y] = nowCell.split('-').map((coord) => parseInt(coord));
              setActiveRoom(nowRoom);
              setActiveCell(`${x}-${y}`);
            } else {
              setActiveRoom(room);
              setActiveCell(`${x}-${y}`);
            }
          });
        setLevel(room.level);
      }
    }
  };
  const debouncedHandleCellClick = _.debounce(handleCellClick, 500);

  console.log('activeCell', activeCell);

  const roomStyles = activeCell
    ? {
        top:
          activeCell.split('-')[0] >= 13
            ? '-125%'
            : activeCell.split('-')[0] >= 10
              ? `-105%`
              : activeCell.split('-')[0] >= 7
                ? `-95%`
                : activeCell.split('-')[0] >= 5
                  ? '-75%'
                  : activeCell.split('-')[0] >= 3
                    ? '-55%'
                    : '-45%',
        left: `calc(0% - ${parseInt(activeCell.split('-')[1]) * 12}%)`,
      }
    : {
        top: '10%',
        left: '10%',
      };

  const getRoomImage = (room) => {
    if (room?.game_model) {
      if (
        room.game_model.dict_id.startsWith('d.chst') &&
        !room.game_model.is_open
      ) {
        return imgChst;
      } else if (
        room.game_model.dict_id.startsWith('exit') ||
        room.game_model.dict_id.startsWith('enter')
      ) {
        return imgDoor;
      } else if (room.game_model.dict_id.startsWith('floor')) {
        return imgFloor;
      } else if (room.game_model.dict_id.startsWith('d.mob')) {
        // return imgMob;
        return null;
      } else if (
        room.game_model.dict_id.startsWith('d.chst') &&
        room.game_model.is_open
      ) {
        return imgDone;
      } else {
        return null;
      }
    } else if (room.is_remains) {
      return imgDone;
    }
  };

  return (
    <div className="dungeon_container--dungeon">
      <img src={imgMap} alt="Dungeon Map"></img>
      {activeRoom && <DungeonModal activeRoom={activeRoom} />}
      <div className="dungeon_container--dungeon--map">
        <div className="dungeon_container--dungeon--rooms" style={roomStyles}>
          {roomMatrix.map((row, rowIndex) =>
            row.map((room, colIndex) => {
              const availableDirections = calculateAvailableDirections(
                rowIndex,
                colIndex,
                roomMatrix,
                room,
              );
              const roomImage =
                room &&
                (room.game_model || room?.is_remains) &&
                getRoomImage(room);

              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`dungeon_container--dungeon--rooms--cell ${room ? 'content' : 'empty'} ${activeCell === `${rowIndex}-${colIndex}` ? 'active' : ''}`}
                  onClick={() =>
                    debouncedHandleCellClick(
                      rowIndex,
                      colIndex,
                      room,
                      activeCell,
                      activeRoom,
                    )
                  }
                >
                  {activeCell === `${rowIndex}-${colIndex}` ? (
                    <img src={imgActive} alt="" />
                  ) : roomImage ? (
                    <img src={roomImage} alt="" />
                  ) : (
                    // room.game_model && room.game_model.dict_id
                    <></>
                  )}
                  {room && availableDirections.includes('right') && (
                    <p className="arrow_right"></p>
                  )}
                  {room && availableDirections.includes('down') && (
                    <p className="arrow_down"></p>
                  )}
                  {room && availableDirections.includes('left') && (
                    <p className="arrow_left"></p>
                  )}
                  {room && availableDirections.includes('up') && (
                    <p className="arrow_up"></p>
                  )}
                </div>
              );
            }),
          )}
        </div>
      </div>
      <div className="dungeon_container--dungeon--level">{level}</div>
    </div>
  );
}

export default DungeonRooms;
