import React from 'react';
import './styles.scss';
import MapWorld from '../../map/mapWorld';
import { useSelector } from 'react-redux';
import Location1 from '../../locations/location1/Location1';
import Location2 from '../../locations/location2/Location2';
import Location3 from '../../locations/location3/Location3';
import Location4 from '../../locations/location4/Location4';
import Location5 from '../../locations/location5/Location5';
import DungeonBase from '../../dungeons/DungeonBase';
import NpcBase from '../../npc/npcBase';
import DungeonEnter from '../../dungeons/dungeonEnter/DungeonEnter';
import EventBase from '../../npc/RandomEvent/EventBase';

const PlayingFieldMain = () => {
  const mapOpen = useSelector((state) => state.mapOpen.open);
  const userDataLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const locationList = new Map([
    ['loc.1', <Location1 />],
    ['loc.2', <Location2 />],
    ['loc.3', <Location3 />],
    ['loc.4', <Location4 />],
    ['loc.5', <Location5 />],
    ['loc.6', <Location1 />],
    ['loc.7', <Location1 />],
  ]);
  const enterDungeon = useSelector(
    (state) => state.DungeonsData.enterModal.open,
  );

  const event = userDataLocation?.random_event_id;

  return (
    <>
      {userDataLocation.building_id.startsWith('bld') &&
      !userDataLocation.location_id.startsWith('dng') ? (
        <NpcBase />
      ) : userDataLocation.location_id.startsWith('dng') ? (
        <DungeonBase />
      ) : mapOpen ? (
        <MapWorld />
      ) : (
        locationList.get(userDataLocation.location_id)
      )}
      {enterDungeon && <DungeonEnter />}
      {event && <EventBase />}
    </>
  );
};

export default PlayingFieldMain;
