import React from 'react';
import { Naming } from '../../../helper/naming';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';
import imgAxe from '../../../media/img/weapons/axe/axe.png';
import imgAxe2 from '../../../media/img/weapons/axe/axe2.png';
import { useDictionary } from '../../../hooks/useDictionary';
import { useSelector } from 'react-redux';
import Images from '../../../helper/Images';
import Lang from '../../language';

const InventoryHubThingsItemOther = ({ toItem, init = null }) => {
  const [dict, getInfoDictionary, getInfoDictionaryName] = useDictionary();
  const info =
    init === null ? getInfoDictionary('equipments', toItem.dict_id) : init;

  const item = getInfoDictionary('equipments', toItem.dict_id);
  const spell = getInfoDictionary('equipments', item.spell_id);
  console.log('item', item);
  console.log('info', info);

  const toDest = (destination) => {
    switch (destination) {
      case 0:
        return null;
      case 1:
        return <Lang>ON_ME</Lang>;
      case 2:
        return <Lang>ON_ANTO</Lang>;
      case 3:
        return <Lang>ON_RANDOM_ANTO</Lang>;
      case 4:
        return <Lang>ON_TEAMT</Lang>;
      case 5:
        return <Lang>ON_ANTO_ALL</Lang>;
      case 6:
        return null;
      case 7:
        return <Lang>ON_TEAMT_ALL</Lang>;
      case 8:
        return <Lang>ON_RANDOM_TEAMT</Lang>;
      default:
        return null;
    }
  };

  if (info) {
    return (
      <div className="inventory__container__hub--body--things--item" style={{}}>
        <div className="inventory__container__hub--body--things--item--box">
          <div
            className={`inventory__container__hub--body--things--item--box--icon rr-${info.quality}`}
          >
            <Images name={info.id} />
          </div>
          <div className="inventory__container__hub--body--things--item--box--head">
            <div className="inventory__container__hub--body--things--item--box--head--title">
              <Lang>{info.id}</Lang> {/* Мок пока-что нет списка имен */}
            </div>
            <div className="inventory__container__hub--body--things--item--box--head--other">
              <div className="inventory__container__hub--body--things--item--box--head--other--char">
                <Lang>{info.item_type === 2 ? 'ELIXIR' : 'SCROL'}</Lang>
              </div>
            </div>
          </div>
        </div>
        <div
          className="inventory__container__hub--body--things--item--box"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <div className="inventory__container__hub--body--things--item--box--info--title">
            <Lang>CHARACTERISTICS</Lang>
          </div>
          <div className="inventory__container__hub--body--things--item--box--buffs">
            <Images name={info.spell_id} />
            <div className="inventory__container__hub--body--things--item--box--buffs--info">
              <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">
                <Lang>TOYOU</Lang>:
              </div>
              <div
                className="inventory__container__hub--body--things--item--box--buffs--info--desc"
                style={{ maxWidth: '200px' }}
              >
                <Lang>{'DESC' + spell?.id}</Lang>
              </div>
              <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">
                <Lang>DESTINATION</Lang>:
              </div>
              <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">
                {toDest(info?.destination)}
              </div>
            </div>
          </div>
        </div>
        {info.levels?.length &&
          info.levels
            .filter((i) => i.level === toItem.level)
            .map((i, index) => (
              <div
                key={'infolevelsCo' + index}
                className="inventory__container__hub--body--things--item--box--info"
              >
                <div className="inventory__container__hub--body--things--item--box--info--title">
                  <Lang>PRICE</Lang>
                </div>
                <div className="inventory__container__hub--body--things--item--box--info--other">
                  <img src={imgG} alt="" /> {i.price[1]}
                </div>
                <div className="inventory__container__hub--body--things--item--box--info--other">
                  <img src={imgE} alt="" /> {i.price[2]}
                </div>
              </div>
            ))}
      </div>
    );
  }
};

export default InventoryHubThingsItemOther;
