import React, { useEffect, useRef, useState } from 'react';
import imgChar from '../../../media/img/game/playingField/map/char.png';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import { checkTime, checkTimeFormat } from '../../../helper/helpers';
import imgClose from '../../../media/img/inventory/close.png';
import { setOpenMap } from '../../../store/reducer/Slices/SlicesMap';

const devMode = false;

const MapWorld = () => {
  const dispatch = useDispatch();
  const colorPoints = devMode ? 'black' : 'transparent';
  const userDataLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const [requestSend] = useRequest();
  const objectSize = '5%';
  const intermediatePointSize = '5%';
  const intermediatePointSizeW = '6px';
  const intermediatePointSizeH = '6px';
  const delayBetweenPoints = 0;

  const [objects, setObjects] = useState([
    {
      id: 'loc.1',
      x: 28,
      y: 11,
      width: objectSize,
      height: objectSize,
      color: 'transparent',
      name: 'Таурунь',
    },
    {
      id: 'loc.2',
      x: 32,
      y: 23,
      width: objectSize,
      height: objectSize,
      color: 'transparent',
      name: 'Железный лес',
    },
    {
      id: 'loc.3',
      x: 20,
      y: 20,
      width: objectSize,
      height: objectSize,
      color: 'transparent',
      name: 'Балий холм',
    },
    {
      id: 'loc.4',
      x: 41.5,
      y: 21,
      width: objectSize,
      height: objectSize,
      color: 'transparent',
      name: 'Алатырь-гора',
    },
    {
      id: 'loc.5',
      x: 36.5,
      y: 33,
      width: objectSize,
      height: objectSize,
      color: 'transparent',
      name: 'Хмельной камень',
    },
    {
      id: 'C',
      x: 31.5,
      y: 19,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'D',
      x: 32.5,
      y: 21,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'A',
      x: 27,
      y: 15.5,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'B',
      x: 25,
      y: 17,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'E',
      x: 35,
      y: 29,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'F',
      x: 36,
      y: 32,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'G',
      x: 39,
      y: 28,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'J',
      x: 40.4,
      y: 24,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'U',
      x: 39,
      y: 20,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
    {
      id: 'Y',
      x: 35.4,
      y: 17,
      width: intermediatePointSizeW,
      height: intermediatePointSizeH,
      color: colorPoints,
    },
  ]);

  const initLocation = objects.find(
    (e) => e.id === userDataLocation?.location_id,
  );

  const [character, setCharacter] = useState({
    id: initLocation.id,
    x: `calc(${initLocation.x}% + 1.2vw)`,
    y: `calc(${initLocation.y}% + 0.4vw)`,
    width: '1vw',
    height: '1vw',
    color: 'transparent',
    target: null,
    currentRoute: [],
    currentLocation: { x: 28, y: 13 },
  });

  const [isPaused, setIsPaused] = useState(false); // пауза необходима если на карте появляется интерактив и т.д.
  const [activeBack, setActiveBack] = useState(false);
  const [animationProgress, setAnimationProgress] = useState(0);
  const [closeButton, setCloseButton] = useState(true);

  const currentLocation = useRef({ x: 10, y: 30 });
  const animationRef = useRef(null);
  const stopTimeout = useRef(null);

  const calculateRoute = (targetId) => {
    const startId = character.id;
    const path = findPath(startId, targetId);

    if (path.length > 0) {
      const route = path
        .map((pointId) => {
          const obj = objects.find((obj) => obj.id === pointId);
          if (obj.name === undefined) {
            return obj ? { x: `${obj.x}%`, y: `${obj.y}%` } : null;
          } else {
            return obj
              ? {
                  x: `calc(${obj.x}% + 1.2vw)`,
                  y: `calc(${obj.y}% + 0.4vw)`,
                }
              : null;
          }
        })
        .filter(Boolean);

      return route;
    } else {
      console.log('Нет пути между объектами');
      return [];
    }
  };

  const moveCharacter = (route) => {
    let currentSegmentStartTime = Date.now();
    // const totalDuration = route.length * delayBetweenPoints;

    const move = () => {
      if (isPaused) {
        animationRef.current = requestAnimationFrame(move);
        return;
      }

      const currentTime = Date.now();
      const elapsed = currentTime - currentSegmentStartTime;
      const time =
        +(userDataLocation?.finish_time * 1000 - currentTime) /
        (route.length - 1);
      const progress = Math.min(1, elapsed / time);

      if (progress === 1) {
        if (route.length === 0) {
          cancelAnimationFrame(animationRef.current);
          return;
        }
        route.shift();
        currentSegmentStartTime = currentTime;
      }

      const currentPoint = route[0];

      if (currentPoint) {
        setCharacter((prevCharacter) => ({
          ...prevCharacter,
          x: currentPoint.x,
          y: currentPoint.y,
        }));

        currentLocation.current = {
          x: currentPoint.x,
          y: currentPoint.y,
        };

        // setAnimationProgress((prevProgress) => prevProgress + progress / (route.length - 1));
        animationRef.current = requestAnimationFrame(move);
      }
    };

    animationRef.current = requestAnimationFrame(move);
  };

  useEffect(() => {
    if (character.currentRoute.length > 0) {
      moveCharacter(character.currentRoute);
    }
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [character.currentRoute, isPaused]);

  const handleObjectClick = (targetId) => {
    requestSend
      .sendData('RequestLocationMove', { dest_id: targetId })
      .then((resp) => {
        console.log('resp', resp[0].$type);
        if (resp[0].$type === 4) {
          return;
        } else {
          setCloseButton(false);

          const route = calculateRoute(targetId);

          if (route.length > 0) {
            setCharacter((prevCharacter) => ({
              ...prevCharacter,
              id: targetId,
              target: null,
              currentRoute: route,
            }));
          }
        }
      });
  };

  const findPath = (startId, targetId) => {
    const paths = [
      ['loc.1', 'C', 'D', 'loc.2'],
      ['loc.1', 'C', 'D', 'loc.2', 'E', 'F', 'loc.5'],
      ['loc.1', 'A', 'B', 'loc.3'],
      ['loc.1', 'Y', 'U', 'loc.4'],
      ['loc.2', 'D', 'C', 'loc.1'],
      ['loc.2', 'E', 'F', 'loc.5'],
      ['loc.2', 'D', 'C', 'loc.1', 'A', 'B', 'loc.3'],
      ['loc.2', 'E', 'F', 'loc.5', 'G', 'J', 'loc.4'],
      ['loc.3', 'B', 'A', 'loc.1'],
      ['loc.3', 'B', 'A', 'loc.1', 'C', 'D', 'loc.2'],
      ['loc.4', 'U', 'Y', 'loc.1', 'A', 'B', 'loc.3'],
      ['loc.3', 'B', 'A', 'loc.1', 'Y', 'U', 'loc.4'],
      ['loc.3', 'B', 'A', 'loc.1', 'C', 'D', 'loc.2', 'E', 'F', 'loc.5'],
      ['loc.4', 'U', 'Y', 'loc.1'],
      ['loc.4', 'U', 'Y', 'loc.1', 'A', 'B', 'loc.3'],
      ['loc.4', 'J', 'G', 'loc.5'],
      ['loc.4', 'J', 'G', 'loc.5', 'F', 'E', 'loc.2'],
      ['loc.5', 'F', 'E', 'loc.2'],
      ['loc.5', 'F', 'E', 'loc.2', 'D', 'C', 'loc.1'],
      ['loc.5', 'F', 'E', 'loc.2', 'D', 'C', 'loc.1', 'A', 'B', 'loc.3'],
      ['loc.5', 'G', 'J', 'loc.4'],
    ];

    return (
      paths.find(
        (path) => path[0] === startId && path[path.length - 1] === targetId,
      ) || []
    );
  };

  const togglePause = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  useEffect(() => {
    if (userDataLocation.pause_time) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  }, [userDataLocation]);

  // Начало нового кода для масштабирования и перемещения карты
  const [mapState, setMapState] = useState({
    scale: 1,
    position: { x: 0, y: 0 },
    isDragging: false,
    origin: { x: 0, y: 0 },
  });

  const handleMouseDown = (e) => {
    setMapState((prevState) => ({
      ...prevState,
      isDragging: true,
      origin: { x: e.clientX, y: e.clientY },
    }));
  };

  const handleMouseMove = (e) => {
    if (!mapState.isDragging || mapState.scale === 1) return;

    const maxX = 450 * ((mapState.scale - 1) / (2.2 - 1));
    const minX = -500 * ((mapState.scale - 1) / (2.2 - 1));
    const maxY = 290 * ((mapState.scale - 1) / (2.2 - 1));
    const minY = -250 * ((mapState.scale - 1) / (2.2 - 1));

    let newX = mapState.position.x + (e.clientX - mapState.origin.x);
    let newY = mapState.position.y + (e.clientY - mapState.origin.y);

    if (newX > maxX) newX = maxX;
    if (newX < minX) newX = minX;
    if (newY > maxY) newY = maxY;
    if (newY < minY) newY = minY;

    setMapState((prevState) => ({
      ...prevState,
      position: { x: newX, y: newY },
      origin: { x: e.clientX, y: e.clientY },
    }));
  };

  const handleMouseUp = () => {
    setMapState((prevState) => ({
      ...prevState,
      isDragging: false,
    }));
  };

  const handleWheel = (e) => {
    if (!closeButton) return;
    const newScale = mapState.scale + e.deltaY * -0.01;
    const scale = Math.min(Math.max(1, newScale), 2.2);

    setMapState((prevState) => ({
      ...prevState,
      scale,
      position: { x: 0, y: 0 },
    }));
  };

  const mapStyle = {
    transform: `translate(${mapState.position.x}px, ${mapState.position.y}px) scale(${mapState.scale})`,
  };

  const objectStyle = (object) => ({
    left: `${object.x}%`,
    top: `${object.y}%`,
    // width: `calc(${object.width} * ${mapState.scale})`,
    // height: `calc(${object.height} * ${mapState.scale})`,
    width: `calc(${object.width})`,
    height: `calc(${object.height})`,
    backgroundColor: object.color,
  });

  const closeMap = () => {
    dispatch(setOpenMap(false));
  };

  useEffect(() => {
    setActiveBack(true);
    clearTimeout(stopTimeout.current);
    stopTimeout.current = setTimeout(() => {
      setActiveBack(false);
    }, 500);
  }, [animationRef.current]);

  useEffect(() => {
    if (!!userDataLocation?.destination_id) {
      setCloseButton(false);

      const route = calculateRoute(userDataLocation?.destination_id);

      if (route.length > 0) {
        setCharacter((prevCharacter) => ({
          ...prevCharacter,
          id: userDataLocation?.destination_id, // Добавлено обновление ID персонажа
          target: null,
          currentRoute: route,
        }));
      }
    }
  }, []);

  return (
    <div
      className="map"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onWheel={handleWheel}
    >
      {/*<div className="buttons">*/}
      {/*    <button onClick={togglePause}>{isPaused ? 'Продолжить' : 'Пауза'}</button>*/}
      {/*</div>*/}
      {closeButton && (
        <div className="map__close" onClick={() => closeMap()}>
          <img src={imgClose} alt="закрыть" />
        </div>
      )}
      <div
        className={`map--content ${activeBack && 'PENone'} ${!devMode && 'devMode'}`}
        style={mapStyle}
      >
        <div
          className={`map--content__back ${activeBack ? 'map--content__back--active' : ''}`}
        />
        <div className="map--content--inner">
          <div className="map--content--inner--map" />
        </div>
        {objects.map((object) => (
          <div
            key={object.id}
            className={`map-object ${object.id === character.id ? 'characterMap' : ''}`}
            onClick={() => handleObjectClick(object.id)}
            style={objectStyle(object)}
            name={object.name}
            id={object.id}
          ></div>
        ))}
        <div
          className={`map-object characterMap`}
          style={{
            left: character.x,
            top: character.y,
            width: character.width,
            height: character.height,
            backgroundColor: character.color,
          }}
        >
          <img src={imgChar} alt="" />
        </div>
      </div>
      <div className="map-edge top">
        <div className="top--item">
          <div className="top--item--img" />
        </div>
      </div>
      <div className="map-edge bottom">
        <div className="bottom--item">
          <div className="bottom--item--img" />
        </div>
      </div>
      <div className="map-edge left">
        <div className="left--item">
          <div className="left--item--img" />
        </div>
      </div>
      <div className="map-edge right">
        <div className="right--item">
          <div className="right--item--img" />
        </div>
      </div>
    </div>
  );
};

export default MapWorld;
