import React, { useEffect, useState } from 'react';
import imgFlag from '../../media/img/game/character/flagBlue.png';
import imgFlagUser from '../../media/img/game/character/flag.png';
import ActiveBarHub from '../activeBar/ActiveBarHub';
import CharacterAntagonist from '../character/CharacterAntagonist';
import Tooltip from '../tooltip/Tooltip';
import CharacterInfoTooltip from '../character/Info/CharacterInfoTooltip';
import CharacterInfoTooltip2 from '../character/CharacterInfoTooltip';
import imgMini1 from '../../media/img/game/character/mini/mini1.png';
import imgMini2 from '../../media/img/game/character/mini/mini2.png';
import imgMini3 from '../../media/img/game/character/mini/mini3.png';
import imgMini4 from '../../media/img/game/character/mini/mini4.png';
import { useSelector } from 'react-redux';
import ActiveBar from '../activeBar/ActiveBar';
import Lang from '../language';
import { Naming } from '../../helper/naming';
import { useDictionary } from '../../hooks/useDictionary';
import { useRequest } from '../../hooks/useRequest';

const BattleAntagonistBox = ({ pairCurrent, disableTooltip = false, user }) => {
  const [requestSend] = useRequest();
  const battleStatus = useSelector((state) => state.battle.status);
  const [dict, getInfoDictionary] = useDictionary();
  const battleData = useSelector((state) => state.battle.effects);
  const [openHover, setOpenHover] = useState(false);
  const battleStart = useSelector((state) => state.battle.data);
  const [oppHp, setOppHp] = useState(null);

  const getInfoForUser = () => {
    if (!openHover)
      requestSend.sendData('RequestHeroInfo', {
        id: pairCurrent.hero_data.h_info.id,
      });
    setOpenHover(true);
  };

  useEffect(() => {
    if (battleStart) {
      const opp = battleStart?.party2.filter(
        (el) => el.hero_id === pairCurrent?.hero_data?.h_info?.id,
      );
      if (opp) setOppHp(opp[0]?.hp);
    }
  }, [battleStart, pairCurrent]);

  return (
    <div
      className="battle__antagonist--container--bigBox"
      // style={{visibility: pairCurrent?.hero_id ? 'visible' : 'hidden'}}
    >
        <div className="game--container__left--block__head">
          <img
            className="game--container__left--block__head--flag"
            src={user ? imgFlagUser : imgFlag}
            alt=""
          />
          <div className="game--container__left--block__head--level">
            <div className="game--container__left--block__head--level--num">
              {pairCurrent?.glory}
            </div>
          </div>
          <div className="game--container__left--block__head--info">
            <Tooltip
              key="1111"
              zIndex={9990}
              onHover={() => getInfoForUser()}
              onLeave={() => setOpenHover(false)}
              content={<CharacterInfoTooltip2 />}
            >
              <div className="game--container__left--block__head--info--name">
                {pairCurrent?.hero_data?.h_info?.name.split('$')[0]}
              </div>
            </Tooltip>
            <div className="game--container__left--block__head--info--stat">
              {battleStatus === 0 ||
              battleStatus === 1 ||
              battleStatus === 3 ? (
                <>
                  <Tooltip
                    key="112"
                    zIndex={9990}
                    maxWidth="450px"
                    content={<CharacterInfoTooltip choice="112" />}
                  >
                    <ActiveBar
                      classOne="game--container__left--block__head--info--stat--bar-bg"
                      classTwo="game--container__left--block__head--info--stat--bar-bg--active"
                      one={pairCurrent?.hero_data?.level?.exp}
                      two={
                        dict?.hero_levels?.find(
                          (i) =>
                            i.level === pairCurrent?.hero_data?.level?.level,
                        )?.exp_next_level
                      }
                    />
                    <div className="game--container__left--block__head--info--stat--bar-bg--active--level">
                      <Lang>{Naming.lvl}</Lang>{' '}
                      {pairCurrent?.hero_data?.level?.level}
                    </div>
                    <div className="game--container__left--block__head--info--stat--bar-bg--active--exp">
                      {pairCurrent?.hero_data?.level?.exp} /{' '}
                      {
                        dict?.hero_levels?.find(
                          (i) =>
                            i.level === pairCurrent?.hero_data?.level?.level,
                        )?.exp_next_level
                      }
                    </div>
                  </Tooltip>
                </>
              ) : (
                <ActiveBarHub
                  hp={
                    oppHp
                      ? oppHp
                      : pairCurrent?.hero_data?.char?.characters.find(
                          (item) => item.char === 22,
                        )?.points
                  }
                  hpMax={
                    pairCurrent?.hero_data?.char?.characters.find(
                      (item) => item.char === 21,
                    )?.points
                  }
                  mind={
                    pairCurrent?.hero_data?.char?.characters.find(
                      (item) => item.char === 44,
                    )?.points
                  }
                  mindMax={
                    pairCurrent?.hero_data?.char?.characters.find(
                      (item) => item.char === 75,
                    )?.points
                  }
                  armor={
                    pairCurrent?.hero_data?.char?.characters.find(
                      (item) => item.char === 31,
                    )?.points
                  }
                  armorMax={
                    pairCurrent?.hero_data?.char?.characters.find(
                      (item) => item.char === 76,
                    )?.points
                  }
                />
              )}
            </div>
          </div>
        </div>

      <CharacterAntagonist
        pairCurrent={pairCurrent}
        effects={battleData}
        disableTooltip={disableTooltip}
      />

      <div className="game--container__left--block__characteristics">
        <div className="game--container__left--block__characteristics--mini">
          <div className="game--container__left--block__characteristics--mini--box">
            <Tooltip
              key="Acharacters27"
              zIndex={9990}
              content={
                <CharacterInfoTooltip
                  choice="27"
                  hero_id={pairCurrent?.hero_data?.h_info?.id}
                />
              }
            >
              <div className="game--container__left--block__characteristics--mini--box--item">
                <img src={imgMini1} alt="" />
                {
                  pairCurrent?.hero_data?.char?.characters.find(
                    (item) => item.char === 27,
                  ).points
                }
              </div>
            </Tooltip>
            <Tooltip
              key="Acharacters28"
              zIndex={9990}
              content={
                <CharacterInfoTooltip
                  choice="28"
                  hero_id={pairCurrent?.hero_data?.h_info?.id}
                />
              }
            >
              <div className="game--container__left--block__characteristics--mini--box--item">
                <img src={imgMini2} alt="" />
                {
                  pairCurrent?.hero_data?.char?.characters.find(
                    (item) => item.char === 28,
                  ).points
                }
              </div>
            </Tooltip>
            <Tooltip
              key="Acharacters29"
              zIndex={9990}
              content={
                <CharacterInfoTooltip
                  choice="29"
                  hero_id={pairCurrent?.hero_data?.h_info?.id}
                />
              }
            >
              <div className="game--container__left--block__characteristics--mini--box--item">
                <img src={imgMini3} alt="" />
                {
                  pairCurrent?.hero_data?.char?.characters.find(
                    (item) => item.char === 29,
                  ).points
                }
              </div>
            </Tooltip>
            <Tooltip
              key="Acharacters30"
              zIndex={9990}
              content={
                <CharacterInfoTooltip
                  choice="30"
                  hero_id={pairCurrent?.hero_data?.h_info?.id}
                />
              }
            >
              <div className="game--container__left--block__characteristics--mini--box--item">
                <img src={imgMini4} alt="" />
                {
                  pairCurrent?.hero_data?.char?.characters.find(
                    (item) => item.char === 30,
                  ).points
                }
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleAntagonistBox;
