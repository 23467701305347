import React, { useEffect, useState } from 'react';

import imgMini1 from '../../../media/img/game/character/miniInfo/mini1.png';
import imgMini2 from '../../../media/img/game/character/miniInfo/mini2.png';
import imgMini3 from '../../../media/img/game/character/miniInfo/mini3.png';
import { useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import { useDictionary } from '../../../hooks/useDictionary';
import Lang from '../../language';
import Tooltip from '../../tooltip/Tooltip';
import CharacterInfoTooltip from './CharacterInfoTooltip';

const CharacterInfo = ({ freePoints, char, body, noFreeP, mob = false }) => {
  const userCharMain = useSelector((state) => state.allUserData.data?.char);
  const userInvent = useSelector((state) => state.allUserData.data?.body);
  const userLevel = useSelector((state) => state.allUserData.data?.level);
  const [info, setInfo] = useState(undefined);
  const [weaponLeft, setWeaponLeft] = useState(null);
  const [requestSend] = useRequest();
  const [dict, getInfoDictionary] = useDictionary();
  const [userChar, setUserChar] = useState(char ? char : userCharMain);
  const [minDam, setMinDam] = useState(0);
  const [maxDam, setMaxDam] = useState(0);

  useEffect(() => {
    if (userInvent) {
      const toInfo = userInvent.equipments.find((i) => i.slot === 3);
      const toInfoLeft = userInvent.equipments.find((i) => i.slot === 8);
      if (toInfo)
        setInfo(getInfoDictionary('equipments', toInfo.model.dict_id));
      if (toInfoLeft) {
        const leftWeapon = getInfoDictionary(
          'equipments',
          toInfoLeft.model.dict_id,
        );
        if (leftWeapon && leftWeapon?.equipment_type === 1) {
          setWeaponLeft(leftWeapon);
        }
      }
    } else if (body) {
      const toInfo = body.equipments.find((i) => i.slot === 3);
      const toInfoLeft = body.equipments.find((i) => i.slot === 8);
      if (toInfo)
        setInfo(getInfoDictionary('equipments', toInfo.model.dict_id));
      if (toInfoLeft) {
        const leftWeapon = getInfoDictionary(
          'equipments',
          toInfoLeft.model.dict_id,
        );
        if (leftWeapon.equipment_type === 1) {
          setWeaponLeft(leftWeapon);
        }
      }
    }
    return () => {
      setInfo(undefined);
      setWeaponLeft(null);
    };
  }, [userInvent]);

  useEffect(() => {
    setUserChar(char ? char : userCharMain);
  }, [char, userCharMain]);

  useEffect(() => {
    const calcDamage = () => {
      const calculateWeaponDamage = (weapon) => {
        if (!weapon) return { min: 0, max: 0 };

        let minDamage = weapon.damage_min;
        let maxDamage = weapon.damage_max;

        if (weapon.weapon_type >= 1 && weapon.weapon_type <= 4) {
          const weaponBonus = userChar.characters.find(
            (item) => item.char === 64 + weapon.weapon_type,
          );
          if (weaponBonus) {
            minDamage += weaponBonus.points;
            maxDamage += weaponBonus.points;
          }
        }

        return { min: minDamage, max: maxDamage };
      };

      const infoDamage = calculateWeaponDamage(info);
      const weaponLeftDamage = calculateWeaponDamage(weaponLeft);

      const bonus = userChar.characters.find((item) => item.char === 33);
      const minBonus = bonus?.points || 0;
      const maxBonus = minBonus;

      const minStr =
        userChar.characters.find((item) => item.char === 34)?.points || 0;
      const maxStr =
        userChar.characters.find((item) => item.char === 79)?.points || 0;

      const minDamage =
        infoDamage.min + weaponLeftDamage.min + minBonus + minStr;
      const maxDamage =
        infoDamage.max + weaponLeftDamage.max + maxBonus + maxStr;

      setMinDam(minDamage);
      setMaxDam(maxDamage);
    };

    calcDamage();
  }, [info, weaponLeft, userChar]);

  const toOnClick = (char) => {
    requestSend.sendData('RequestCharacterUpdate', { char });
  };

  if (!userChar || Object.keys(userChar).length === 0) {
    return null;
  }

  return (
    <>
      <div className="game--container__left--block__info">
        <div className="game--container__left--block__info--box">
          <Tooltip
            key="FORCE"
            zIndex={9990}
            maxWidth="450px"
            content={<CharacterInfoTooltip choice="1" />}
          >
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name">
                <Lang>FORCE</Lang>
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div
                  className="game--container__left--block__info--box--item--boost"
                  onClick={() => toOnClick(1)}
                >
                  +
                </div>
              )}
              <div className="game--container__left--block__info--box--item--val">
                {mob
                  ? '?'
                  : userChar.characters.find((item) => item.char === 3).points}
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div className="game--container__left--block__info--box--item--plus">
                  + 1
                </div>
              )}
            </div>
          </Tooltip>
          <Tooltip
            key="DEXTERITY"
            zIndex={9990}
            maxWidth="450px"
            content={<CharacterInfoTooltip choice="4" />}
          >
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name">
                <Lang>DEXTERITY</Lang>
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div
                  className="game--container__left--block__info--box--item--boost"
                  onClick={() => toOnClick(4)}
                >
                  +
                </div>
              )}
              <div className="game--container__left--block__info--box--item--val">
                {mob
                  ? '?'
                  : userChar.characters.find((item) => item.char === 6).points}
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div className="game--container__left--block__info--box--item--plus">
                  + 1
                </div>
              )}
            </div>
          </Tooltip>
          <Tooltip
            key="INTUITION"
            zIndex={9990}
            maxWidth="450px"
            content={<CharacterInfoTooltip choice="12" />}
          >
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name">
                <Lang>INTUITION</Lang>
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div
                  className="game--container__left--block__info--box--item--boost"
                  onClick={() => toOnClick(12)}
                >
                  +
                </div>
              )}
              <div className="game--container__left--block__info--box--item--val">
                {mob
                  ? '?'
                  : userChar.characters.find((item) => item.char === 14).points}
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div className="game--container__left--block__info--box--item--plus">
                  + 1
                </div>
              )}
            </div>
          </Tooltip>
          <Tooltip
            key="INTELLIGENCE"
            zIndex={9990}
            maxWidth="450px"
            content={<CharacterInfoTooltip choice="23" />}
          >
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name">
                <Lang>INTELLIGENCE</Lang>
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div
                  className="game--container__left--block__info--box--item--boost"
                  onClick={() => toOnClick(23)}
                >
                  +
                </div>
              )}
              <div className="game--container__left--block__info--box--item--val">
                {mob
                  ? '?'
                  : userChar.characters.find((item) => item.char === 25).points}
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div className="game--container__left--block__info--box--item--plus">
                  + 1
                </div>
              )}
            </div>
          </Tooltip>
          <Tooltip
            key="HEALTH"
            zIndex={9990}
            maxWidth="450px"
            content={<CharacterInfoTooltip choice="19" />}
          >
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name">
                <Lang>HEALTH</Lang>
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div
                  className="game--container__left--block__info--box--item--boost"
                  onClick={() => toOnClick(19)}
                >
                  +
                </div>
              )}
              <div className="game--container__left--block__info--box--item--val">
                {mob
                  ? '?'
                  : userChar.characters.find((item) => item.char === 19).points}
              </div>
              {!noFreeP && userLevel?.free_points > 0 && (
                <div className="game--container__left--block__info--box--item--plus">
                  + 1
                </div>
              )}
            </div>
          </Tooltip>
        </div>
        <div className="game--container__left--block__info--box">
          <div className="game--container__left--block__info--box__block">
            <Tooltip
              key="DAMAGE"
              zIndex={9990}
              maxWidth="450px"
              content={<CharacterInfoTooltip choice="32" />}
            >
              <div className="game--container__left--block__info--box--item">
                <div className="game--container__left--block__info--box--item--name-mini">
                  <Lang>DAMAGE</Lang>
                </div>
                <div className="game--container__left--block__info--box--item--stat">
                  {mob ? (
                    '?'
                  ) : (
                    <>
                      <img src={imgMini1} alt="" />
                      {minDam}-{maxDam}
                    </>
                  )}
                </div>
                <div className="game--container__left--block__info--box--item--stat">
                  <img src={imgMini3} alt="" />
                  {userChar.characters.find((item) => item.char === 32).points >
                  0
                    ? userChar.characters.find((item) => item.char === 32)
                        .points
                    : null}
                </div>
              </div>
            </Tooltip>
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name-mini">
                <Lang>DODGE_TITLE</Lang>
              </div>
              <Tooltip
                key="DODGE_TITLE"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="7" />}
              >
                <div className="game--container__left--block__info--box--item--stat">
                  <img src={imgMini2} alt="" />
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 9)
                          .points > 0
                      ? userChar.characters.find((item) => item.char === 9)
                          .points
                      : null}
                </div>
              </Tooltip>
              <Tooltip
                key="ANTI_DODGE"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="11" />}
              >
                <div className="game--container__left--block__info--box--item--stat">
                  <img src={imgMini1} alt="" />
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 11)
                          .points > 0
                      ? userChar.characters.find((item) => item.char === 11)
                          .points
                      : null}
                </div>
              </Tooltip>
            </div>
            <div className="game--container__left--block__info--box--item">
              <div className="game--container__left--block__info--box--item--name-mini">
                <Lang>CRETE</Lang>
              </div>
              <Tooltip
                key="CRETE"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="16" />}
              >
                <div className="game--container__left--block__info--box--item--stat">
                  <img src={imgMini1} alt="" />
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 16)
                          .points > 0
                      ? userChar.characters.find((item) => item.char === 16)
                          .points
                      : null}
                </div>
              </Tooltip>
              <Tooltip
                key="ANTI_CRETE"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="18" />}
              >
                <div className="game--container__left--block__info--box--item--stat">
                  <img src={imgMini2} alt="" />
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 18)
                          .points > 0
                      ? userChar.characters.find((item) => item.char === 18)
                          .points
                      : null}
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="game--container__left--block__info--box__block2">
            <Tooltip
              key="MAXIMUM_HP"
              zIndex={9990}
              maxWidth="450px"
              content={<CharacterInfoTooltip choice="21" />}
            >
              <div className="game--container__left--block__info--box--item">
                <div className="game--container__left--block__info--box--item--name-mini auto">
                  <Lang>MAXIMUM_HP</Lang>
                </div>
                <div className="game--container__left--block__info--box--item--stat">
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 21)
                        .points}
                </div>
              </div>
            </Tooltip>
            <Tooltip
              key="SPIRIT"
              zIndex={9990}
              maxWidth="450px"
              content={<CharacterInfoTooltip choice="44" />}
            >
              <div className="game--container__left--block__info--box--item">
                <div className="game--container__left--block__info--box--item--name-mini auto">
                  <Lang>SPIRIT</Lang>
                </div>
                <div className="game--container__left--block__info--box--item--stat">
                  {mob
                    ? '?'
                    : userChar.characters.find((item) => item.char === 44)
                          .points > 0
                      ? userChar.characters.find((item) => item.char === 44)
                          .points
                      : null}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {!noFreeP && userLevel?.free_points > 0 && (
        <div className="game--container__left--block__notify">
          <Lang>YOU_HAVE</Lang> <b>{userLevel.free_points}</b>{' '}
          <Lang>FREE_STAT_POINTS</Lang>
        </div>
      )}
    </>
  );
};

export default CharacterInfo;
